import * as stringify from 'qs/lib/stringify';

const API_URI = ((localStorage.getItem('API_ORIGIN') || process.env.VUE_APP_API_ORIGIN) + '/a/c.do')
    .replace('//a/', '/a/');

const errorHandle = (code) => {
  const codes = {
    '-10': {
      message: false,
      method() {
        vm.$router.replace('/login');
      },
    },
  };

  return codes[code];
};

const getToken = () => {
  const token =
    localStorage.getItem('token') ||
    location.search.replace(/[&|?].*a=/g, '').replace(/&.*/, '');
  window.HAS_TOKEN = !!token;
  return token;
};

window.$tmUrl = (className, methodName, body = null) => {
  let params = body || {};

  params.call = '';
  params.call += className.replace('Action', '').toLowerCase();
  params.call += '.';
  params.call += methodName;

  // const token = getToken();
  // if (token) {
  //   params.api_token = token;
  // }

  params = stringify(params);
  if (params) params = '?' + params;

  return API_URI + params;
};

const notices = [];
const watch = [];

const noticesResolve = (key) => (...args) =>
  notices.filter((i) => i.key === key).forEach((i) => i.resolve(...args));
const noticesReject = (key) => (...args) =>
  notices.filter((i) => i.key === key).forEach((i) => i.reject(...args));

window.$tm = async (className, methodName, body = null, notError = false) => {
  const key = JSON.stringify({className, methodName, body, notError});
  if (!watch.includes(key)) {
    watch.push(key);

    let params = {};
    const headers = {};

    className = className.replace('Action', '');
    className = className.replace(className[0], className[0].toLowerCase());

    params.call = '';
    params.call += className;
    params.call += '.';
    params.call += methodName;

    headers['content-type'] = 'application/x-www-form-urlencoded';
    body = stringify(body || {});

    // headers["content-type"] = "application/json";
    // body = JSON.stringify(body);

    // if (token) {
    //   headers["Authorization"] = `Bearer ${token}`;
    //   // params.api_token = token;
    // }

    const token = getToken();
    if (token) {
      headers['Authorization'] = `Bearer ${token}`;
      // params.api_token = token;
    }

    params = stringify(params);
    if (params) params = '?' + params;

    const resolve = noticesResolve(key);
    const reject = noticesReject(key);

    window
        .fetch(API_URI + params, {
          method: 'POST',
          headers,
          body,
          mode: 'cors',
        // timeout: 1000
        })
        .then(async (res) => {
          if (res.status < 300 && res.status >= 200) {
            res = await res.json();

            if (res.success) {
              return resolve(res.data);
            }
            if (notError) {
              return reject(res);
            }

            const error = errorHandle(res.code);
            if (error) {
              if (error.method) {
                error.method();
              }
              if (error.message) {
                $error(error.message);
              }

              return reject(res);
            }

            if (res.message) {
              $error(res.message);
            }
            return reject(res);
          }

          reject(res);
        })
        .catch((e, ...args) => {
          if (e && e.message === 'Failed to fetch') {
            $error('获取数据失败，请确认网络是否正常');
          }
          reject(e, ...args);
        })
        .finally(() => {
          watch.splice(watch.indexOf(key), 1);
        });
  }

  return new Promise((resolve, reject) => {
    notices.push({
      key,
      resolve,
      reject,
    });
  });
};
