import {reactive, watch, toRaw} from 'vue';

const key = 'layout.store';

export const init = {
    theme: {
        backgroundColor: '#ffffff',
        textColor: '#303133',
        activeTextColor: '#409eff',
    },
};

const state = reactive(JSON.parse(localStorage.getItem(key)) || init);

watch(state, (val) => {
    localStorage.setItem(key, JSON.stringify(toRaw(val)));
});

export default state;
