module.exports = (loading, defaultIndex) => [
    {
        ...defaultIndex('spike', [
            loading('index', 'spike'),
            loading('_id', 'spike'),
        ]),
        meta: {
            title: '秒杀',
        },
    },
    {
        ...loading('groupAssemble'),
        meta: {
            title: '全返拼',
        },
    }, {
        ...defaultIndex('lottery', [
            loading('index', 'lottery'),
            loading('_id', 'lottery'),
            loading('itemList', 'lottery'),
            loading('itemForm', 'lottery'),
            loading('reserve', 'lottery'),
            loading('reserveForm', 'lottery'),
            loading('whiteList', 'lottery'),
            loading('itemUserList', 'lottery'),
        ]),
        meta: {
            title: '抽奖',
        },
    },
	{
	    ...defaultIndex('videolive', [
	        loading('index', 'videolive'),
	    ]),
	    meta: {
	        title: '视频直播',
	    },
	},
	{
		...loading('giftsOrder'),
		meta: {
		  title: '赠品登记',
		},
    },
];
