const {$ele} = window;

window.$loading = (text = '加载中...', options = {}) => {
  const loading = $ele.$loading({
    text,
    zIndex: 3000,
    ...options,
  });
  return {
    close() {
      loading.close();
    },
  };
};
window.$success = (message = '', options = {}) => {
  return new Promise((resolve) => {
    $ele.$message.success({
      message,
      onClose: resolve,
      zIndex: 3000,
      ...options,
    });
  });
};
window.$info = (message = '', options = {}) => {
  return new Promise((resolve) => {
    $ele.$message.info({
      message,
      onClose: resolve,
      zIndex: 3000,
      ...options,
    });
  });
};
window.$error = (message = '', options = {}) => {
  return new Promise((resolve) => {
    $ele.$message.error({
      message,
      onClose: resolve,
      zIndex: 3000,
      ...options,
    });
  });
};

window.$alert = (message, title, options = {}) => {
  return $ele.$alert(message, title, {
    zIndex: 3000,
    ...options,
  });
};

window.$confirm = (message, title = '', options = {}) => {
  return $ele.$confirm(message, title, {
    zIndex: 3000,
    ...options,
  });
};

window.$prompt = (message, title = '', options = {}) => {
  return $ele.$prompt(message, title, {
    zIndex: 3000,
    ...options,
  });
};
