module.exports = (loading, defaultIndex) => [
    {
        ...defaultIndex('pageModules', [
            loading('index', 'pageModules'),
            loading('_id', 'pageModules'),
        ]),
        meta: {
            title: '页面管理',
        },
    },
    {
        ...loading('tags'),
        meta: {
            title: '搜索标签',
        },
    },
    {
        ...loading('activityLabelList'),
        meta: {
            title: '活动主图装饰',
        },
    },
    {
        ...loading('courseCategory'),
        meta: {
            title: '课程分类',
        },
    },
    {
        ...defaultIndex('course', [
            loading('index', 'course'),
            loading('_id', 'course'),
        ]),
        meta: {
            title: '商学院文章',
        },
    },
    {
        ...defaultIndex('profit-template', [
            loading('index', 'profit-template'),
            loading('_id', 'profit-template'),
        ]),
        meta: {
            title: '利润模板',
        },
    },
    // {
    //     ...loading('zhuboList'),
    //     meta: {
    //         title: '主播列表',
    //     },
    // },
    // {
    //     ...loading('courseList'),
    //     meta: {
    //         title: '课程列表',
    //     },
    // },
];
