module.exports = (loading) => [
  // {
  //   path: 'setting-Qrcode',
  //   component: DefaultIndex,
  //   meta: {
  //     title: '推广二维码设置',
  //   },
  // },
  {
    path: 'customer-service',
    component: loading('customerServiceList'),
    meta: {
      title: '客服设置',
    },
  },
];
