<template>
  <el-dialog v-model="show" :before-close="handleClose">
    <el-form label-width="100px" :model="theme">
      <el-form-item label="背景色">
        <el-color-picker v-model="theme.backgroundColor"/>
      </el-form-item>
      <el-form-item label="文字色">
        <el-color-picker v-model="theme.textColor"/>
      </el-form-item>
      <el-form-item label="文字选中色">
        <el-color-picker v-model="theme.activeTextColor"/>
      </el-form-item>
    </el-form>
    <template #footer>
      <el-button @click="reset">重置</el-button>
    </template>
  </el-dialog>
</template>

<script>
import state, {init} from './state';
export default {
  data() {
    return {
      show: false,
      theme: state.theme,
    };
  },
  methods: {
    handleClose() {
      this.show = false;
    },
    reset() {
      Object.assign(this.theme, init.theme);
    },
  },
};
</script>
