module.exports = (loading, defaultIndex) => [
  {
    ...defaultIndex('', [
      loading('index'),
      loading('_id'),
    ]),
    meta: {
      title: '订单列表',
    },
  },
  {
    ...loading('sendOrder'),
    meta: {
      title: '待发货订单',
    },
  },
  {
    ...loading('refund'),
    meta: {
      title: '售后订单',
    },
  },
  {
    ...loading('orderEvaluate'),
    meta: {
      title: '订单评价',
    },
  },
  {
    ...loading('enforceRefund'),
    meta: {
      title: '强制售后订单',
    },
  },
];
