import ElementUI from './ElementUI';
import './TaiMeiClient';
import './ElementUIExtend';
import './toDecimal';
import ElxPopover from '../components/base/ElxPopover';
import dateFormat from './DateFormat';

export default {
    install: (app) => {
        app.use(ElementUI);

        app.component('ElxPopover', ElxPopover);

        app.config.globalProperties.$fm = (val, type, ...args) => {
            if (!val) return '';
            if (!type) {
                if (!String(val).length === 10) {
                    type = 'date';
                } else {
                    type = 'price';
                }
            }
            if (type === 'date') {
                return dateFormat(val, ...args);
            }
            if (type === 'price') {
                return String(val).toDecimal(...args);
            }
        };

        app.config.globalProperties.append = (pathToAppend, path = location.pathname) =>
            path + (path.endsWith('/') ? '' : '/') + pathToAppend;
    },
};
