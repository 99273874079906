module.exports = (loading, defaultIndex) => [
  {
    ...defaultIndex('', [
      loading('index'),
      loading('_id'),
    ]),
    meta: {
      title: '商户列表',
    },
  },
  {
    ...loading('shopChildGoods'),
    props: (route) => ({query: route.query.shopId}),
  },
];
