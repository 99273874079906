module.exports = (loading, DefaultIndex) => [

/*   {
     ...loading('teamUpgradeUserProfitStatisticsLists'),
     meta: {
         title: '团队收益',
     },
  },
{
    ...loading('teacherUpgradeUserProfitStatisticsLists'),
    meta: {
        title: '讲师收益',
    },
 },
  {
       ...loading('sponsorByLogStatistics'),
       meta: {
           title: '主办方办课详情',
       },
   },
 {
      ...loading('directUserBySortStatistics'),
      meta: {
          title: '直推数量排名',
      },
  },
 {
      ...loading('productSalesByReportStatistics'),
      meta: {
          title: '销售报表',
      },
  }, */
  {
      ...loading('salesRankStatistics'),
      meta: {
          title: '邀约排名',
      },
  },
  {
      ...loading('yearSignRecord'),
      meta: {
          title: '签到记录',
      },
  },
/*  {
    ...loading('groupRecommendUpTreeStatistics'),
    meta: {
      title: '团队上级推荐关系',
    },

  }, */
 /*  {
      ...loading('groupRecommendUpTreeProfitStatistics'),
      meta: {
          title: '团队收益明细',
      },
   }, */
 /*  {
      ...loading('groupRecommendDownTreeStatistics'),
      meta: {
          title: '团队下级推荐关系',
      },
   }, */
 /* {//旧－动态读取
       ...loading('sponsorStatistics'),
       meta: {
           title: '主办方办课详情',
       },
   }, */
];
