<template>
  <div id="layout-container-menu" :class="{collapse}">
    <router-link class="site" to="/">
      <span class="title ml-1">{{ $store.state.site_title }}</span>
    </router-link>
  <el-menu
      ref="menu"
      class="overflow-y-auto layout-container-menus"
      :collapse="collapse"
      :default-openeds="defaultOpeneds"
      :default-active="activeMenu"
      :background-color="theme.backgroundColor"
      :text-color="theme.textColor"
      :active-text-color="theme.activeTextColor"
  >
    <template v-for="(route) in menus">
      <el-sub-menu v-if="route.children" :index="route.key" :key="route.key">
        <template #title>
          <!-- <i class="fa mr-2" :class="route.meta.ico || 'fa-list-ul'" aria-hidden="true"/> -->
		  <i class="fa mr-2" :class="route.meta.titleIcon" aria-hidden="true"/>
          <span>{{ route.meta.title }}</span>
        </template>
        <el-menu-item
            v-for="(cRoute) in route.children"
            :key="cRoute.key"
            :index="cRoute.key"
            @click="onMenuItemClick(cRoute)"
        >
          {{ cRoute.meta.title }}
        </el-menu-item>
      </el-sub-menu>
      <el-menu-item v-if="!route.children" :index="route.key" @click="onMenuItemClick(route)" :key="route.key">
        <!-- <i class="fa mr-2" :class="`${route.meta.ico || 'fa-list-ul'}`" aria-hidden="true"></i> -->
		<i class="fa mr-2" :class="`${route.meta.titleIcon }`" aria-hidden="true"></i>

        <template #title>{{ route.meta.title }}</template>
      </el-menu-item>
    </template>
  </el-menu>
</div>
</template>

<script>
import state from './state';
import {useEventListener} from '@vueuse/core';
import RouterMenusFilter from '@/helpers/RouterMenusFilter';

export default {
  components: {},
  props: ['collapse'],
  data() {
    return {
      activeMenu: '',
      defaultOpeneds: [],
      isCollapse: false,
      theme: state.theme,
      isOpen: false,
    };
  },
  computed: {
    my() {
      return this.$store.state.my;
    },
    menus() {
      return new RouterMenusFilter(
          this.my,
          this.$router.options.routes,
      ).menus();
    },
  },
  watch: {
    '$route.matched'() {
      this.setActiveMenu();
    },
  },
  methods: {
    onMenuItemClick(route) {
      if (this.isOpen) {
        this.isOpen = false;
        window.open(route.fullPath);
      } else {
        this.$router.push(route.fullPath);
      }
    },
    setActiveMenu() {
      const titles = this.$route.matched
          .filter((i) => i.meta && i.meta.title)
          .map((i) => i.meta.title);
      if (titles.length) {
        this.activeMenu = titles.join('');
        return;
      }
      this.activeMenu = this.$route.meta.title;
    },
  },
  created() {
    this.setActiveMenu();
  },
  mounted() {
    const keyCodes = [91, 17];
    useEventListener(document, 'keydown', (evt) => {
      const {keyCode} = evt;
      if (keyCodes.includes(keyCode)) {
        this.isOpen = true;
      }
    });
    useEventListener(document, 'keyup', (evt) => {
      const {keyCode} = evt;
      if (keyCodes.includes(keyCode)) {
        this.isOpen = false;
      }
    });
  },
};
</script>

<style lang="scss">
#layout-container-menu {
  height: 100vh;
  position: sticky;
  top: 0;
  z-index: 1;

  .layout-container-menus {
    max-height: calc(100vh - 60px);
    height: calc(100vh - 60px);
  }

  .site {
    height: 60px;
    line-height: 60px;
    transition: all 0.3s;
    overflow: hidden;
    white-space: nowrap;
    font-size: 20px;
    font-weight: bold;
    width: 100%;
    text-align: center;
    display: block;
  }

  &.collapse {
    .site .title {
      transition: 1s opacity;
      opacity: 0;
    }
  }

  .layout-container-menus{
    &:not(.el-menu--collapse) {
      width: 256px;
    }
    .el-menu-item,
    .el-sub-menu__title {
      height: 40px;
      line-height: 40px;
    }
  }
}
</style>
