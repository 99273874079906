module.exports = (loading, defaultIndex) => [
    {
        ...defaultIndex('', [
            loading('index'),
            loading('_id'),
        ]),
        meta: {
            title: '商品列表',
        },
    }, {
        ...defaultIndex('goodsMaxBuy', [
            loading('index', 'goodsMaxBuy'),
            loading('_id', 'goodsMaxBuy'),
        ]),
        meta: {
            title: '商品限购',
        },
    }, {
        ...defaultIndex('SupplierProductReviews', [
            loading('index', 'SupplierProductReviews'),
            loading('_id', 'SupplierProductReviews'),
        ]),
        meta: {
            title: '供应商商品审核',
        },
    }, {
        ...loading('categories'),
        meta: {
            title: '分类列表',
        },
    }, {
        ...loading('attributes'),
        meta: {
            title: '销售规格',
        },
    }, {
        ...defaultIndex('shipping-templates', [
            loading('index', 'shipping-templates'),
            loading('_id', 'shipping-templates'),
        ]),
        meta: {
            title: '运费模板',
        },
    }, {
        ...loading('suppliers'),
        meta: {
            title: '供应商列表',
        },
    }, {
        ...loading('brand'),
        meta: {
            title: '品牌',
        },
    },
];
