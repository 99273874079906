import {createRouter, createWebHistory} from 'vue-router';
import DefaultIndex from '@/layouts/default';

const loading = (name) => () => import(`@/pages/${name}.vue`);
// const loading = (name) => require(`@/pages/${name}.vue`).default;

const loadingRoute = (dir, filename) => {
  return {
    path: ['index'].includes(filename) ? '': filename.replace('_', ':').replace('/index', ''),
    component: loading([dir, filename].filter(Boolean).join('/')),
    props: true,
  };
};

const chunkLoading = (dir, options) => {
  const children = require(`./${dir}`)(
      (path, subDir) => loadingRoute([dir, subDir].filter(Boolean).join('/'), path),
      (path, children = []) => ({path, component: DefaultIndex, children}),
  );

  return {
    name: `${dir}`,
    path: `${dir}`,
    component: DefaultIndex,
    children,
    ...options,
  };
};

const routes = [
  {
    path: '/login',
    component: loading('login'),
  },
  {
    path: '/',
    component: DefaultIndex,
    children: [
      loadingRoute('', 'index'),
      loadingRoute('', 'test'),
      loadingRoute('', 'my-profile'),
      {
        ...loadingRoute('', 'dashboard'),
        meta: {
          title: '信息面板',
        },
      },
      chunkLoading('orders', {
        meta: {
          title: '订单管理',
        },
      }),
      chunkLoading('goods', {
        meta: {
          title: '商品管理',
        },
      }),
      chunkLoading('market', {
        meta: {
          title: '营销管理',
        },
      }),

      chunkLoading('operations', {
        meta: {
          title: '运营管理',
        },
      }),
      chunkLoading('confcourse', {
        meta: {
          title: '会务管理',
        },
      }),
      chunkLoading('users', {
        meta: {
          title: '用户管理',
        },
      }),
      chunkLoading('shops', {
        meta: {
          title: '商户管理',
        },
      }),
      chunkLoading('finance', {
        meta: {
          title: '财务报表',
        },
      }),
      chunkLoading('system', {
        meta: {
          title: '系统管理',
        },
      }),

    ],
  },
  {
    path: '/:NotFound?',
    redirect: '/',
  },
];

// 路由配置
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
