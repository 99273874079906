module.exports = (loading, defaultIndex) => [
    {
        ...loading('admin/index'),
        meta: {
            title: '管理员列表',
        },
    }, {
        ...loading('admin/role'),
        meta: {
            title: '角色列表',
        },
    },
    {
        ...defaultIndex('remindContent', [
            loading('index', 'remindContent'),
            loading('_id', 'remindContent'),
            loading('variable', 'remindContent'),
        ]),
        meta: {
            title: '内容提醒',
        },
    }, {
        ...loading('customerServiceList'),
        meta: {
            title: '客服设置',
        },
    }, {
        ...loading('payment/wxPayment'),
        meta: {
            title: '微信支付设置',
        },
    }, {
        ...loading('payment/aliPayment'),
        meta: {
            title: '支付宝设置',
        },
    },
	{
	  ...defaultIndex('dict', [
	    loading('index', 'dict'),
		loading('sysDictData', 'dict'),
	  ]),
	  meta: {
	    title: '字典管理',
	  },
	},


];
