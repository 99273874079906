/* eslint-disable no-console */

import {register} from 'register-service-worker';

let isConfirm = false;

register(`${process.env.BASE_URL}service-worker.js`, {
  ready() {
    console.log(
        'App is being served from cache by a service worker.\n' +
          'For more details, visit https://goo.gl/AFskqB',
    );
  },
  /**
   *
   * @param {ServiceWorkerRegistration} swr
   */
  registered(swr) {
    setInterval(async () => {
      await swr.update();
    }, 30 * 1000);
    console.log('Service worker has been registered.');
  },
  cached() {
    console.log('Content has been cached for offline use.');
  },
  updatefound() {
    console.log('New content is downloading.');
  },
  updated(swr) {
    if (isConfirm) return;
    isConfirm = true;
    $confirm('有新内容更新，是否刷新页面？')
        .then(() => {
          window.location.reload();
        })
        .finally(() => {
          isConfirm = false;
        });
    caches.keys().then((cacheList) => {
      return Promise.all(cacheList.map((cacheName) => {
          if (![].includes(cacheName)) {
            return caches.delete(cacheName);
          }
        }));
      });
    console.log('New content is available; please refresh.');
  },
  offline() {
    console.log(
        'No internet connection found. App is running in offline mode.',
    );
  },
  error(error) {
    console.error('Error during service worker registration:', error);
  },
});

