/**
 * xx
 */
export default class {
  /**
   * constructor
   * @param {*} my
   * @param {*} routes
   */
  constructor(my, routes) {
    this.my = my;
    this.routes = routes;
  }

  /**
   * 返回右侧菜单项
   * @return {*}
   */
  menus() {
    const base = this.routes.find((i) => i.path === '/');
    if (base.children) return this.filterMenus(base.children);
    return this.filterMenus(this.routes);
  }

  /**
   * 判断是否可以访问，不可以则返回可以访问的路由
   * @param {Object} route
   * @return {*}
   */
  notAccess(route) {
    for (let i = 0; i < route.matched.length; i++) {
      const matchedRoute = route.matched[i];
      if (matchedRoute.path !== '') {
        if (!this.roleJudgment(matchedRoute)) {
          return this.menus()[0];
        }
      }
    }

    return false;
  }

  /**
   * 角色权限判断
   * @param {Object} route
   * @return {boolean}
   */
  roleJudgment(route) {
    if (!this.my) return true;
    // if (this.my.id === 1) return true;

    if (route.key) {
      const type = this.my.type;
      if (!type && route.key.includes('supplierManagement')) {
        return false;
      } else if (type && !route.key.includes('supplierManagement')) {
        return false;
      }
    }


    if (!route.meta) return false;
    if (!route.meta.roles) return true;
    if (!route.meta.roles.length) return false;

    return route.meta.roles.includes(this.my.role_id);
  }

  /**
   * 过滤返回可以显示的菜单
   * @param {Array} arr
   * @param {string} key
   * @param {string} fullPath
   * @return {Array}
   */
  filterMenus(arr, key = '', fullPath = '') {
    return arr
        .filter((route) => {
          if (!route.meta) return false;
          if (!route.meta.title) return false;
          if (route.meta.disabled) return false;
          return true;
        })
        .map((v) => {
          if (v.meta && v.meta.title) v.key = key + v.meta.title;
          v.fullPath = fullPath + '/' + v.path;
          if (v.children && v.children.length) {
            v.children = this.filterMenus(v.children, v.key, v.fullPath);
          }
          if (v.children && !v.children.length) {
            delete v.children;
          }
          if (v.component) delete v.component;

          // 设定默认展开
          // if (index <= 2 && v.children && v.children.length) this.defaultOpeneds.push(v.name);
          return v;
        })
      .filter((route) => {
        return this.roleJudgment(route);
      });
  }
}
