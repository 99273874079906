<template>
  <el-container class="container-fluid">
    <container-menu :collapse="isCollapse" />
    <el-container>
      <el-header class="flex items-center justify-between w-full bg-white shadow">
        <el-button type="text" @click="isCollapse = !isCollapse">
          <i class="fa text-xl" :class="isCollapse ? 'fa-indent' : 'fa-outdent'" aria-hidden="true"/>
        </el-button>
        <el-dropdown trigger="hover" v-if="my" size="lager">
          <div class="click-area flex items-center">
            <img class="avatar" :src="`https://api.multiavatar.com/u${my.realName+my.userName}.svg`">
            <div class="flex flex-col justify-around">
              <div class="font-bold">{{ my.realName || "" }}</div>
              <div class="text-xs text-gray-600">
                {{ my.userName || "" }}
              </div>
            </div>
          </div>
          <template #dropdown>
          <el-dropdown-menu>
            <el-dropdown-item @click.native="$router.push('/my-profile')">
              个人信息
            </el-dropdown-item>
            <el-dropdown-item @click.native="$refs.settingDialog.show = true">
              主题设置
            </el-dropdown-item>
            <el-dropdown-item @click.native="logout">注销</el-dropdown-item>
          </el-dropdown-menu>
          </template>
        </el-dropdown>
      </el-header>

      <el-main>
        <router-view />
      </el-main>

      <el-footer height="auto" class="text-gray-300">Copyright © 2021</el-footer>
    </el-container>
  </el-container>
  <setting-dialog ref="settingDialog"/>
</template>

<script>
import debounce from 'lodash/debounce';
import ContainerMenu from './ContainerMenu.vue';
import settingDialog from './settingDialog';

export default {
  components: {
    ContainerMenu,
    settingDialog,
  },
  props: [],
  data() {
    return {
      activeMenu: '',
      defaultOpeneds: [],
      isCollapse: false,
    };
  },
  computed: {
    my() {
      return this.$store.state.my;
    },
  },
  methods: {
    async logout() {
      const loading = $loading();
      localStorage.removeItem('token');
      await this.$store.dispatch('getMy');
      loading.close();
    },
  },
  created() {
    this.activeMenu = this.$route.name;

    this.isCollapse = document.body.clientWidth < 1400;
    window.onresize = debounce(() => {
      const screenWidth = document.body.clientWidth;
      this.isCollapse = screenWidth < 1400;
    }, 150);
  },
};
</script>

<style lang="scss">
.container-fluid {
  min-height: 100vh;
  color: rgba(0, 0, 0, 0.65);

  > .el-container {
    > .el-header {
      height: 60px;
      color: rgba(0, 0, 0, 0.65);
      position: sticky;
      top: 0;
      z-index: 2;

      .hamburger {
        margin-left: -20px;
      }

      .click-area {
        cursor: pointer;
      }
      .avatar {
        display: inline-block;
        width: 40px;
        height: 40px;
        padding: 2px;
        margin-right: 10px;
        border-radius: 50%;
        cursor: pointer;
        overflow: hidden;
        vertical-align: middle;
        border: 1px solid #ddd;
      }
    }

    > .el-main,
    > .el-footer {
      background: #f0f2f5;
    }
  }
}
</style>
