module.exports = (loading, DefaultIndex) => [
  {
    ...loading('salesRankStatistics'),
    meta: {
      title: '邀约排名',
    },

  },
  {
      ...loading('yearSignRecord'),
      meta: {
          title: '签到记录',
      },
  },
];
