module.exports = (loading, DefaultIndex) => [
  {
    ...loading('supplierGoodsStatistics'),
    meta: {
      title: '销售报表',
    },
  },
    {
        ...loading('supplierReconciliation'),
        meta: {
            title: '供应商对账',
        },
    },
    {
        ...loading('gosProfit'),
        meta: {
            title: '拼团利润',
        },
    },
  {
   ...loading('withdraws'),
    meta: {
      title: '提现列表',
    },
  },
  {
    ...loading('orderProfit'),
    meta: {
      title: '订单利润',
    },
  },
  {
    ...loading('balanceRecord'),
    meta: {
      title: '余额记录',
    },
  },
  {
    ...loading('prompt'),
    meta: {
      title: '提现提示',
    },
  },
];
