module.exports = (loading, defaultIndex) => [
    {
        ...loading('admin/index'),
        meta: {
            title: '管理员列表',
        },
    }, {
        ...loading('admin/role'),
        meta: {
            title: '角色列表',
        },
    },
    {
        ...defaultIndex('remindContent', [
            loading('index', 'remindContent'),
            loading('_id', 'remindContent'),
            loading('variable', 'remindContent'),
        ]),
        meta: {
            title: '内容提醒',
        },
    }, {
        ...loading('customerServiceList'),
        meta: {
            title: '客服设置',
        },
    },
];
