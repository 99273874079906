if (process.env.NODE_ENV === 'production') {
  require('./registerServiceWorker');
}
import bootstrap from './bootstrap';
import {createApp} from 'vue';
import App from './App.vue';
import store from './store';
import router from './router';

(async () => {
  await store.dispatch('getMy');
  window.vm = createApp(App)
      .use(store)
      .use(router)
      .use(bootstrap)
      .mount('#app');
})();

