import ElementPlus, {ElLoading, ElMessageBox, ElMessage} from 'element-plus';
import zhCn from 'element-plus/es/locale/lang/zh-cn';
import 'element-plus/dist/index.css';

window.$ele = {
  $loading: ElLoading.service,
  $msgbox: ElMessageBox,
  $alert: ElMessageBox.alert,
  $confirm: ElMessageBox.confirm,
  $prompt: ElMessageBox.prompt,
  $message: ElMessage,
};

export default {
  install: (app) => {
    app.use(ElementPlus, {
      locale: zhCn,
      size: 'mini',
      zIndex: 1000,
    });
  },
};
