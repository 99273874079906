<template>
  <router-view v-if="!isAuth"/>
  <container v-else>
    <router-view/>
  </container>
</template>

<style src="./scss/app.scss" lang="scss"></style>

<script>
import Container from '@/components/layout/Container';
export default {
  components: {
    Container,
  },
  computed: {
    isAuth() {
      return !!this.$store.state.my;
    },
  },
  watch: {
    async '$route.fullPath'(val) {
      await this.$nextTick();
      if (val.includes('/test')) return;
      const excps = [
          '/login',
          '/',
      ];
      if (excps.includes(val) && this.isAuth) {
        this.$router.replace('/dashboard');
      } else if (!excps.includes(val) && !this.isAuth) {
        this.$router.replace('/login');
      }
    },
    async '$route.matched'(val) {
      await this.$nextTick();
        const defaultSiteTitle = `${process.env.VUE_APP_DEFAULT_TITLE || ''}`;
        let title;

        if (Array.isArray(val)) {
          val = val.filter((i) => i.meta && i.meta.title);

          if (val.length) {
            title = val
                .map((i) => i.meta.title)
                .reverse()
                .join(` - `);
          }
        }

        if (title) {
          document.title = `${title} - ${defaultSiteTitle}`;
        } else if (document.title !== defaultSiteTitle) {
          document.title = defaultSiteTitle;
        }
    },
  },
  mounted() {
    // this.rrwebInit();
  },
  methods: {
    async init() {
      this.isNotFirst = this.getFirst();
      if (this.isNotFirst) return;
      this.refresh();
      this.setFirst();
    },
    getFirst() {
      const time = +localStorage.getItem('refuse_resource_time');
      if (Date.now() - time > 1000 * 60 * 60 * 24) return;

      return !!time;
    },
    setFirst() {
      localStorage.setItem('refuse_resource_time', Date.now() + '');
    },
    refresh() {
      $tm('AdminAction', 'refreshAResource');
    },
    rrwebInit() {
      ((url, appId) => {
        if (!location.host.includes('taimeizmx')) return;
        if (!window.hasOwnProperty('MutationObserver')) return;
        if (!window.hasOwnProperty('fetch')) return;
        if (!window.hasOwnProperty('Promise')) return;

        let socket;
        const ljs = (src) => {
          return new Promise((resolve) => {
            const script = document.createElement('script');
            script.src = src;
            document.head.appendChild(script);
            script.onload = resolve;
          });
        };
        Promise.all([
          ljs(`${url}socket.io/socket.io.js`),
          ljs('https://cdn.jsdelivr.net/combine/npm/rrweb/dist/record/rrweb-record.min.js'),
        ]).then(() => {
          socket = io(url);
          return getSessionId();
        }).then((visitorId) => {
          const queues = [];

          rrwebRecord({
            emit(event) {
              queues.push(event);
            },
          });

          const startQueues = () => {
            const event = queues[0];
            if (!event) return setTimeout(() => startQueues(), 1000);
            queues.splice(0, 1);

            socket.emit('create', 'events', {
              visitorId,
              event,
            }, (error) => {
              if (error) return;
              startQueues();
            });
          };
          startQueues();
        });

        const getSessionId = () => {
          return new Promise((resolve) => {
            const id = sessionStorage.getItem('id');
            socket.emit('create', 'visitors', {
              siteId: appId,
              id,
            }, (error, res) => {
              if (error || !res || !res.id) throw new Error(JSON.stringify(error));
              sessionStorage.setItem('id', res.id);
              resolve(res.id);
            });
          });
        };
      })('https://rrweb-panel.d.flyyweb.com/', 1);
    },
  },
  created() {
    this.init();
  },
};
</script>
