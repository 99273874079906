import {createStore} from 'vuex';

export default createStore({
  state: {
    site_title: `${process.env.VUE_APP_DEFAULT_TITLE || ''}`,
    my: null,
  },
  mutations: {
    setMy(state, user = null) {
      state.my = user;
    },
  },
  actions: {
    async getMy(store) {
      let res = null;
      try {
        res = await $tm('AdminAction', 'currentAdminMessage');
      } catch (e) {
        // localStorage.removeItem('token');
      }
      store.commit('setMy', res);
    },
  },
});
