/**
 * 1234 | toDecimal
 * 12.34
 *
 * 1234 | toDecimal(0)
 * 12
 *
 * 1234 | toDecimal(1)
 * 34
 */
Object.assign(String.prototype, {
  toDecimal: function(type = null, digits = 2) {
    let val = this;
    if (val.toString() === 'undefined') return '';

    let minus = '';
    if (val.includes('-')) {
      val = val.replace('-', '');
      minus = '-';
    }

    let integer = val.substr(0, val.length - digits);
    if (!integer) integer = 0;
    if (type === 0) return integer;

    const decimal = val.substr(-digits, digits).padStart(digits, '0');
    if (type === 1) return decimal;

    return `${minus}${integer}.${decimal}`;
  },
});

