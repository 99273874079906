<template>
<el-popover :visible="show" trigger="manual" v-bind="$attrs">
  <div ref="refPopover">
    <slot />
  </div>
  <template #reference>
    <span class="popover-reference" @click="show = !show">
      <slot name="reference"/>
    </span>
  </template>
</el-popover>
</template>

<script>
import {onClickOutside} from '@vueuse/core';

export default {
  name: 'ElxPopover',
  props: ['trigger', 'visible'],
  emits: ['update:visible'],
  data() {
    return {
      show: false,
    };
  },
  watch: {
    visible(val) {
      if (this.show === val) return;
      this.show = val;
    },
    show(val) {
      if (this.visible === val) return;
      this.$emit('update:visible', val);
    },
  },
  mounted() {
    this.eventInit();
  },
  methods: {
    eventInit() {
      onClickOutside(this.$refs.refPopover, (event) => {
        if (!this.show) return;
        const pathClassNames = event.path.map((i) => i.className).filter((i) => !!i).join('|');
        const hasClassNames = [
          'el-popover el-popper is-light',
          'el-cascader-panel',
          'el-select-dropdown__item',
          'el-color-picker__panel',
          'popover-reference',
        ];

        for (const className of hasClassNames) {
          if (pathClassNames.includes(className)) {
            return;
          }
        }
        this.show = false;
      });
    },
  },
};
</script>
